import React from "react";
import { useSpring, animated } from "react-spring";
import "./ComingSoon.scss";
import { useTranslation } from "react-i18next";

const ComingSoon = () => {
  const { t } = useTranslation();
  const fadeInScale = useSpring({
    from: { opacity: 0, transform: "scale(0.5)" },
    to: { opacity: 1, transform: "scale(1)" },
    config: { tension: 150, friction: 15 },
  });

  const colorChange = useSpring({
    loop: true,
    from: { color: "#ff0000" }, // Starting color
    to: { color: "#00ff00" }, // Ending color
    config: { duration: 2000 },
  });

  return (
    <div className="coming-soon-container">
      <animated.h1 style={{ ...fadeInScale, ...colorChange }}>
        {t("coming_soon")}
      </animated.h1>
    </div>
  );
};

export default ComingSoon;
