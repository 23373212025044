import React, { useState } from "react";
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import SearchIcon from "@mui/icons-material/Search";
// import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
// import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Link } from "react-router-dom";
import "./Navbar.scss";
import Cart from "../Cart/Cart";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import EuroIcon from '@mui/icons-material/Euro';

const Navbar = ({ changeLanguage }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const products = useSelector((state) => state.cart.products);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="hamburger-menu">
          <div
            className={`hamburger-icon ${mobileMenuOpen ? "open" : ""}`}
            onClick={toggleMobileMenu}
          >
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </div>
        <div className="logo">
          <Link className="link" to="/">
            Jyrox
          </Link>
        </div>

        <div className="icons-mobile">
          <div className="cartIcon-mobile" onClick={() => setOpen(!open)}>
            <ShoppingCartOutlinedIcon />
            <span>{products.length}</span>
          </div>
        </div>

        <div className={`menu ${mobileMenuOpen ? "active" : ""}`}>
          <div className="right">
            <div className="item">
              <Link className="link" to="/">
                {t("homepage")}
              </Link>
            </div>
            <div className="item">
              <Link className="link" to="/">
                {t("about")}
              </Link>
            </div>
            <div className="item">
              <Link className="link" to="/products/2">
                {t("men")}
              </Link>
            </div>
            <div className="item">
              <Link className="link" to="/products/1">
                {t("women")}
              </Link>
            </div>
            <div className="icons">
              <select onChange={(e) => changeLanguage(e.target.value)}>
                <option value="en">En</option>
                <option value="fr">De</option>
                {/* Add more languages as needed */}
              </select>
              <div className="cartIcon" onClick={() => setOpen(!open)}>
                <ShoppingCartOutlinedIcon />
                <span>{products.length}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`mobile-menu ${mobileMenuOpen ? "active" : ""}`}>
        <div className="item">
          <Link className="link" to="/">
            {t("homepage")}
          </Link>
        </div>
        <div className="item">
          <Link className="link" to="/">
            {t("about")}
          </Link>
        </div>
        <div className="item">
          <Link className="link" to="/products/2">
            {t("men")}
          </Link>
        </div>
        <div className="item">
          <Link className="link" to="/products/1">
            {t("women")}
          </Link>
        </div>
      </div>
      {open && <Cart />}
    </div>
  );
};

export default Navbar;
