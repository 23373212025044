import React from "react";
import { useTranslation } from "react-i18next";
import "./Footer.scss";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="top">
        <div className="item">
          <h1>{t("Categories")}</h1>
          <div className="categories-list">
            <span>{t("men")}</span>

            <span>{t("women")}</span>
            {/* <span>Shoes</span> */}
            {/* <span>Accessories</span> */}
            {/* <span>New Arrivals</span> */}
          </div>
        </div>
        <div className="item">
          <h1>{t("links")}</h1>
          <div className="links-list">
            <span>{t("faq")}</span>
            <span>{t("pages")}</span>
            <span>{t("stores")}</span>
            <span>{t("compare")}</span>
          </div>
          {/* <span>Cookies</span> */}
        </div>
        <div className="item about-section">
          <h1>{t("about")}</h1>
          <span>{t("about_text")}</span>
        </div>
        <div className="item contact-section">
          <h1>{t("contact")}</h1>
          <span>{t("contact_text")}</span>
        </div>
      </div>
      <div className="bottom">
        <div className="left">
          <span className="footer-logo">Jyrox</span>
          <span className="copyright">{t("copyrrite")}</span>
        </div>
        <div className="right">
          <img src="/img/payment.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
